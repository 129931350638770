<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showOutput="true"
			:showAdd="$hasAccess('report.profit/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.key" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.tp" style="width: 120px" clearable placeholder="场站特性">
					<el-option v-for="(item, index) in tpList" :label="item" :value="index" v-bind:key="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.city" style="width: 120px" clearable placeholder="区域">
					<el-option v-for="(item, index) in $userArea" :label="item" :value="index" v-bind:key="index">
					</el-option>
				</el-select>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="group_name" title="站点名称" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="city" title="区域" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="tp" title="场站特性" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{ tpList[row.tp] }}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="device_cost" title="设备成本" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="build_cost" title="建设成本" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="area_cost" title="场地费 元/个/月" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="parking_num" title="车位数" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="relations_cost" title="公关费用" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="profit" title="纯利润" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{ row.profit || "-" }}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="back" title="回本周期" align="center" min-width="50px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="agreement_cycle" title="合同周期" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="runday" title="运营时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="runtime" title="运营时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" title="操作" align="center" width="220px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detail(row)">查看</el-button>
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('report.profit/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="deleteOne(row)"
						v-if="$hasAccess('report.profit/del')">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增/编辑 -->
		<el-dialog :title="editDialogFormData.id > 0 ? '编辑' : '新增'" :visible.sync="editDialogShow"
			:close-on-click-modal="false" :close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="区域" prop="city">
					<el-select v-model="editDialogFormData.city" placeholder="请选择" style="width: 100%" @change="changeCity">
						<el-option label="全部" value="10"></el-option>
						<el-option v-for="(item, k) in $userArea" :key="k" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="场站" prop="group_id">
					<el-select v-model="editDialogFormData.group_id" placeholder="请选择场站" style="width: 100%">
						<el-option v-for="(item, k) in groupList" :key="k" :label="item.group_name" :value="item.group_id" :disabled="item.disabled"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="场站特性" prop="tp">
					<el-select v-model="editDialogFormData.tp" placeholder="请选择场站特性" style="width: 100%">
						<el-option v-for="(item, k) in tpList" :key="k" :label="item"
							:value="k"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="设备成本" prop="device_cost">
					<el-input-number type="number" :min="0" :max="1000000" :step="100" :precision="2"
						v-model="editDialogFormData.device_cost" class="input" size="medium">
					</el-input-number>元
				</el-form-item>
				<el-form-item label="建设成本" prop="build_cost">
					<el-input-number type="number" :min="0" :max="1000000" :step="100" :precision="2"
						v-model="editDialogFormData.build_cost" class="input" size="medium">
					</el-input-number>
					元
				</el-form-item>
				<el-form-item label="场地费" prop="area_cost">
					<el-input-number type="number" :min="0" :max="10000" :step="10" :precision="2"
						v-model="editDialogFormData.area_cost" class="input" size="medium">
					</el-input-number>
					元/个/月
				</el-form-item>
				<el-form-item label="公关费用" prop="relations_cost">
					<el-input-number type="number" :min="0" :max="1000000" :step="100" :precision="2"
						v-model="editDialogFormData.relations_cost" class="input" size="medium">
					</el-input-number>
					元
				</el-form-item>
				<el-form-item label="车位数" prop="parking_num">
					<el-input-number type="number" :min="0" :max="1000" :step="1" :precision="0"
						v-model="editDialogFormData.parking_num" class="input" size="medium">
					</el-input-number>
					个
				</el-form-item>
				<el-form-item label="合同周期" prop="agreement_cycle">
					<el-input-number type="number" :min="0" :max="240" :step="1" :precision="0"
						v-model="editDialogFormData.agreement_cycle" class="input" size="medium">
					</el-input-number>
					月
				</el-form-item>
				<el-form-item label="运营时间" prop="runtime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.runtime" type="date"
						value-format="yyyy-MM-dd" placeholder="运营时间"></el-date-picker>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Tables from '@/components/tables'
export default {
	name: 'report-profit-list',
	components: {
		Tables
	},
	data() {
		return {
			// 表格信息
			tableName: '站点利润',
			isLoading: false,
			totalPage: 0,
			searchFormData: {},
			List: [],
			tpList: { //站点特性
				"1": "快充+慢充",
				"2": "快充",
				"3": "慢充"
			},
			groupList: [], //场站列表
			selecttime: {},
			rules: {
				group_id: [{
					required: true,
					message: '请选择场站',
					trigger: 'change'
				}]
			},
			// 编辑
			editDialogShow: false,
			editDialogFormData: { cfg: {} },
		}
	},
	computed: {
	},
	mounted() {
		//this.getList();
	},
	activated() { 
		//this.getList(1, 20);
		this.$refs.xTable.refreshTable();
	},
	methods: {
		// 表格列表
		async getList(currentPage, pageSize) {
			let params = {
				token: this.$store.state.user.token,
				page: currentPage,
				size: pageSize
			}
			params = Object.assign(params, this.searchFormData);
			if(params.city){
				params.city = this.$userArea[params.city];
			}
			this.isLoading = true
			const res = await this.$api.Report.Profit.List(params);
			this.List = res.data;
			for (let v of this.List) {
				if (v.profit > 0) {
					v.back = ((Number(v.device_cost) + Number(v.build_cost) + Number(v.relations_cost)) / (Number(v.profit)  / v.runday) / 30).toFixed(0);
				} else {
					v.back = '-';
				}
				if(v.runtime) v.runtime = v.runtime.substr(0, 10)
			}
			this.totalPage = res.total
			this.isLoading = false
		},
		// 重置搜索
		clearSearch() {
			this.searchFormData = {}
		},
		// 重置form
		resetFormData() {
			this.editDialogFormData = { city: '10' }
			if (this.$refs.editForm) {
				this.$refs.editForm.resetFields()
			}
		},
		// 新增
		addOne() {
			this.resetFormData();
			this.editDialogShow = true;
			this.getGroup();
		},
		// 编辑
		async editOne(row) {
			this.resetFormData();
			//row.city = row.city.toString();
			this.editDialogFormData = Object.assign({}, row);
			delete this.editDialogFormData.runday;
			delete this.editDialogFormData.profit;
			this.editDialogFormData['old_group_id'] = this.editDialogFormData['group_id'];
			delete this.editDialogFormData._XID;
			this.editDialogFormData.tp = this.editDialogFormData.tp.toString();
			this.editDialogShow = true;
			this.getGroup();
		},
		// 删除
		deleteOne(row) {
			this.$confirm('确定要删除该记录吗', '提示', {
				cancelButtonClass: 'btn-custom-cancel',
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				const params = {
					token: this.$store.state.user.token,
					group_id: row.group_id
				}
				await this.$api.Report.Profit.Del(params)
				this.$notify({
					title: '成功',
					message: '删除成功',
					type: 'success'
				})
				this.$refs.xTable.refreshTable()
			})
		},
		// 关闭弹窗
		closeDialog() {
			this.editDialogShow = false;
			this.$refs.xTable.refreshTable();
		},
		// 编辑保存
		saveEdit() {
			this.$refs.editForm.validate(async valid => {
				//console.log('valid', valid)
				if (valid) {
					delete this.editDialogFormData.group;
					delete this.editDialogFormData.group_name;
					delete this.editDialogFormData.city;
					delete this.editDialogFormData.profit;
					delete this.editDialogFormData.profitDay;
					delete this.editDialogFormData.back;
					let old_group_id = this.editDialogFormData.old_group_id;
					delete this.editDialogFormData.old_group_id;
					const params = {
						token: this.$store.state.user.token,
						form: JSON.stringify(this.editDialogFormData)
					}
					if (old_group_id) {
						await this.$api.Report.Profit.Edit(params);
					} else {
						await this.$api.Report.Profit.Add(params);
					}
					this.$notify({
						title: '成功',
						message: '操作成功',
						type: 'success'
					})
					this.closeDialog();
				}
			})
		},
		changeCity(e) { //修改城市
			this.getGroup();
		},
		getGroup() { //获取场站
			const params = {
				token: this.$store.state.user.token,
				page: 1,
				size: 100,
				city: this.editDialogFormData.city == 10 ? null : this.editDialogFormData.city
			}
			this.$api.Device.GetGroupMinList(params).then((res) => {
				this.groupList = res;
				for(let v of this.groupList){
					v.disabled = false;
					for(let v1 of this.List){
						if(v1.group_id == v.group_id){
							v.disabled = true;
							break;
						}
					}
				}
			});
		},
		// 统计记录
		detail(row) {
			this.$router.push({
				name: "report-profit-statisticslist",
				query: { group_id: row.group_id, area_cost: row.area_cost * row.parking_num },
			});
		},
	}
}
</script>

<style lang="scss" scoped></style>
